* {
    animation-name: up-to-down;
    animation-duration: .9s;
}

html {
    scroll-behavior: smooth;
}

@keyframes up-to-down {
    /* Up to down Animation. */

    0% {
      opacity: 0;
      transform: translateY(-20px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
}

#home {
    text-align: center;
    margin-top: 180px;
    margin-bottom: 260px;
}

#home h1 {
    font-weight: 700;
    color: #fff;
    font-size: 272%;
}

#home p {
    color: #9BA1A6;
    font-size: 120%;
}

#about, #faqs, #pricing{
    background-color: #111417;
    text-align: center;
    padding-bottom: 35px;
}

#about img {
    width: 100%;
    margin-bottom: -50%;
}

.about-header {
    font-weight: 700;
    color: #fff;
    font-size: 36px;
}

#about p {
    color: #9BA1A6;
    font-size: 120%;
}

.card-title {
    color: #fff;
    font-weight: 800;
}

.card-icon {
    margin-bottom: -20px;
    color: #1890ff;
}

.noback .card {
    background-color: inherit;
    border-width: 0px;
}

.nav-item {
    font-size: 16px;
}

.fa-check {
    color: #1890ff;
}

.fa-times {
    color: #ed586c;
}

li {
    list-style: none;
}

#contacts{
    text-align: center;
    padding-bottom: 35px;
}

.overlay-left {
    position: absolute;
    width: 543px;
    height: 543px;
    left: -305px;
    top: 89px;
    background: radial-gradient(50% 50% at 50% 50%, rgba(8, 70, 186, 0.45) 0%, rgba(8, 85, 186, 0.15) 64.58%, rgba(8, 135, 186, 0) 100%);
}

.overlay-right {
    position: absolute;
    width: 250px;
    height: 250px;
    left: 43%;
    top: -150px;
    background: radial-gradient(50% 50% at 50% 50%, rgba(8, 70, 186, 0.45) 0%, rgba(8, 85, 186, 0.15) 64.58%, rgba(8, 135, 186, 0) 100%);
}

.overlay-top {
    position: absolute;
    width: 376px;
    height: 405px;
    left: -250px;
    background: radial-gradient(50% 50% at 50% 50%, rgba(8, 70, 186, 0.45) 0%, rgba(8, 85, 186, 0.15) 64.58%, rgba(8, 135, 186, 0) 100%);
}

.sidebar-link {
    cursor: pointer;
}

.table td, .table th {
  font-size: 14px;
}
